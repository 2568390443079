<template>
  <div id="app">
    <keep-alive>
      <router-view :key="$route.path" />
    </keep-alive>
  </div>
</template>

<script>
import '@/css/main.scss'

export default {
  name: "App",
  created () {
    let debug = this.$global.GetQueryString('db')
    if (debug) {
      this.$store.commit('setDebug', true)
    }
    let deviceType = 'desktop'; // 默认设备类型
    if (typeof this.$ssrContext !== 'undefined' && this.$ssrContext.device) {
      // SSR 渲染，使用服务端提供的设备类型
      deviceType = this.$ssrContext.device;
    } else {
      // 客户端渲染，使用浏览器信息检测设备类型
      const userAgent = navigator.userAgent.toLowerCase();
      // const isMobile = window.innerWidth < 768; // 假设屏幕宽度小于768px为移动设备
      // /mobile|android|iphone|ipad|phone/i.test(userAgent)
      if (/mobile|android|iphone|ipad|phone/i.test(userAgent)) {
        deviceType = 'mobile';
      } else {
        deviceType = 'desktop';
      }
      // 在 Vuex 中存储设备类型，或者在组件中使用
      this.$store.commit('setDeviceType', deviceType);
    }

  },
};
</script>
