import Vue from 'vue'
import App from './App.vue'
import createRouter from './router'
import createStore from './store'
const placeholderimage = require('@/assets/default/placeholder.png')
import VueLazyload from 'vue-lazyload'
import global from '@/utils/global'
import VueMeta from 'vue-meta'

Vue.prototype.$global = global

Vue.use(VueMeta)
Vue.use(VueLazyload, {
	preLoad: 1.3,
	error: placeholderimage,
	loading: placeholderimage,
	attempt: 1,
})

export default function createApp() {
	const router = createRouter()
	const store = createStore()
	const app = new Vue({
		router,
		store,
		render: h => h(App),
	})
	return {
		app,
		router,
		store,
	}
}
