import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default function createStore() {
	return new Vuex.Store({
		state: {
			host: '',
			// deviceType: 'pc',
			deviceType: 'mobile',
			showDebug: false,
			domainConfig: {
				IAmTitle: 'PeopleLife',
				IAMEMAIL: 'peoplelife.support@gmail.com',
				IAmURL: 'peoplelife.lifestyle',
				IAMTYPE: 'people',
				iamkeywords: 'PeopleLife',

				ABOUTUS: 'Discover diverse cultures at People Life. Explore captivating photography and stories that highlight global lifestyles and shared human experiences.',
				meta: {
					title: 'Default Title',
					des: 'Default des',
				},

				firebase: {
					apiKey: 'AIzaSyDPCzERUQwfWUSnOMxfKN_l1JI8B5jvPl0',
					authDomain: 'web-a2bf7.firebaseapp.com',
					projectId: 'web-a2bf7',
					storageBucket: 'web-a2bf7.appspot.com',
					messagingSenderId: '780267482533',
					appId: '1:780267482533:web:e507793b763ec8c9bf0c4c',
					measurementId: 'G-180ZCK8Q70',
				},
				ads: 'google.com, pub-8381337407740666, DIRECT, f08c47fec0942fa0',
			},
		},
		mutations: {
			setHost(state, host) {
				state.host = host
			},
			setTitle(state, title) {
				state.title = title
			},
			setDomain(state, domainConfig) {
				state.domainConfig = domainConfig
			},
			setDeviceType(state, deviceType) {
				state.deviceType = deviceType
			},
			setDebug(state, showDebug) {
				state.showDebug = showDebug
			},
			setAdsense(state, adsenseConfig) {
				state.adsensConfig = adsenseConfig
			},
		},
		getters: {
			showDebug: (state) => state.showDebug,
			deviceType: (state) => state.deviceType,
			host: (state) => state.host,
			adsensConfig: (state) => state.adsensConfig,
			adPosConfig: (state) => state.adPosConfig,
			domainConfig: (state) => state.domainConfig,
		},
	})
}
